import { render, staticRenderFns } from "./stok.vue?vue&type=template&id=ddbfd938&scoped=true&"
import script from "./stok.vue?vue&type=script&lang=js&"
export * from "./stok.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddbfd938",
  null
  
)

export default component.exports