<template>
  <div>
    <b-alert show>
      <Zondicon icon="user-solid-circle" style="width: 32px" />
      Stok Yönetimi
      <b-alert class="p-0" show>Mevcut {{ stoku.mevcutAdet }}</b-alert>
    </b-alert>

    <form @submit.prevent="stokKaydet">
      <b-container fluid class="p-2">
        <b-row>
          <b-col cols="12" right>
            <b-button-toolbar aria-label="">
              <b-button-group class="mx-1">
                <b-button @click="oncekiStok">&lsaquo;</b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button type="submit">Kaydet</b-button>
                <b-button>Kopyala</b-button>
                <b-button>Sil</b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button @click="sonrakiStok">&rsaquo;</b-button>
              </b-button-group>

              <b-input-group>
                <vue-instant
                  :suggestOnAllWords="true"
                  suggestion-attribute="ad"
                  :disabled="false"
                  v-model="arananStok"
                  @enter="arananStokGit"
                  @selected="arananStokGit"
                  :show-autocomplete="true"
                  :autofocus="false"
                  :suggestions="$store.state.sirketAyarlar.tumStoklar"
                  name="ad"
                  placeholder="Stoğa Geç"
                  type="google"
                ></vue-instant>
              </b-input-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid class="p-2">
        <b-tabs content-class="mt-3">
          <b-tab title="Genel Bilgi" active>
            <b-row>
              <b-col cols="12">
                <b-container fluid>
                  <b-row>
                    <b-col cols="12" xl="6" lg="6">
                      <b-row class="p-0">
                        <b-col cols="12">
                          <b-form-checkbox v-model="stoku.aktif"> Aktif </b-form-checkbox>
                        </b-col>
                        <b-col cols="12">
                          Stok Kod
                          <b-input-group size="sm">
                            <template #prepend>
                              <b-button v-b-tooltip title="Otomatik Kod" @click="otokod">
                                <b-icon icon="journal-plus"></b-icon>
                              </b-button>
                            </template>

                            <b-form-input
                              v-model="stoku.kod"
                              @blur="$v.stoku.kod.$touch()"
                              :class="{ invalidData: $v.stoku.kod.$error }"
                            />
                          </b-input-group>
                        </b-col>
                        <b-col cols="12">
                          Ad/Tanım
                          <b-form-input
                            v-model="stoku.ad"
                            @blur="$v.stoku.ad.$touch()"
                            :class="{ invalidData: $v.stoku.ad.$error }"
                          />
                        </b-col>
                        <b-col cols="12">
                          Kısa Açıklama
                          <b-form-textarea
                            id="textarea"
                            placeholder="Kısa Açıklama..."
                            rows="3"
                            max-rows="6"
                            v-model="stoku.kisaAciklama"
                          />
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col cols="12" xl="6" lg="6">
                      <b-row class="p-0">
                        <b-col cols="12" xl="6" lg="6">
                          Alış Fiyat

                          <vue-autonumeric
                            class="form-control"
                            style="text-align: right"
                            v-model="stoku.alisFiyat"
                            :options="{
                              currencySymbolPlacement: 's',
                              decimalCharacter: ',',
                              decimalPlaces: 2,
                              digitGroupSeparator: '.',
                              suffixText: ' ₺',
                            }"
                          ></vue-autonumeric>
                        </b-col>
                        <b-col cols="12" xl="6" lg="6">
                          Satış Fiyat

                          <vue-autonumeric
                            class="form-control"
                            style="text-align: right"
                            v-model="stoku.satisFiyat"
                            :options="{
                              currencySymbolPlacement: 's',
                              decimalCharacter: ',',
                              decimalPlaces: 2,
                              digitGroupSeparator: '.',
                              suffixText: ' ₺',
                            }"
                          ></vue-autonumeric>
                        </b-col>
                      </b-row>

                      <b-row class="p-0">
                        <b-col cols="12" xl="6" lg="6">
                          Kritik Uyarı

                          <vue-autonumeric
                            class="form-control"
                            style="text-align: right"
                            v-model="stoku.kritikStokUyari"
                          ></vue-autonumeric>
                        </b-col>
                        <b-col cols="12" xl="6" lg="6">
                          Kargo Ücreti

                          <vue-autonumeric
                            class="form-control"
                            style="text-align: right"
                            v-model="stoku.kargoUcreti"
                            :options="{
                              currencySymbolPlacement: 's',
                              decimalCharacter: ',',
                              decimalPlaces: 2,
                              digitGroupSeparator: '.',
                              suffixText: ' ₺',
                            }"
                          ></vue-autonumeric>
                        </b-col>
                        <b-col cols="12" xl="6" lg="6">
                          Birim
                          <multiselect
                            v-model="stoku.sistemStokBirimlerId"
                            tag-placeholder="STKB"
                            placeholder="STKB"
                            label="ad"
                            track-by="Id"
                            :options="$store.state.sirketAyarlar.sistemStokBirimleri"
                          ></multiselect>
                        </b-col>
                        <b-col cols="12" xl="6" lg="6">
                          KDV Oran
                          <multiselect
                            v-model="stoku.kdvOran"
                            tag-placeholder="KDV"
                            placeholder="KDV"
                            label="oran"
                            track-by="Id"
                            :options="$store.state.sirketAyarlar.sistemKdvOranlar"
                          ></multiselect>
                        </b-col>
                        <b-col cols="12" xl="6" lg="6">
                          Marka
                          <multiselect
                            v-model="stoku.sistemMarkalarId"
                            tag-placeholder="Marka"
                            placeholder="Marka"
                            label="ad"
                            track-by="Id"
                            :options="$store.state.sirketAyarlar.markaListesi"
                          ></multiselect>
                        </b-col>

                        <b-col cols="12" xl="6" lg="6">
                          GTIN No
                          <b-input v-model="stoku.gtin"></b-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      Genel Açıklama
                      <br />
                      <ckeditor v-model="stoku.aciklama"></ckeditor>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" xl="3" lg="3">
                      Resim
                      <vue-dropify
                        :src="
                          stoku.resim1 != ''
                            ? $store.state.portalContentAdres +
                              $store.state.calisilanSirketId +
                              '/images/' +
                              stoku.resim1
                            : require(`../../assets/urunResmiYok.png`)
                        "
                        accept="image/*"
                        v-model="res1"
                      ></vue-dropify>
                    </b-col>

                    <b-col cols="12" xl="3" lg="3">
                      Resim
                      <vue-dropify
                        :src="
                          stoku.resim2 != ''
                            ? $store.state.portalContentAdres +
                              $store.state.calisilanSirketId +
                              '/images/' +
                              stoku.resim2
                            : require(`../../assets/urunResmiYok.png`)
                        "
                        accept="image/*"
                        v-model="res2"
                      ></vue-dropify>
                    </b-col>
                    <b-col cols="12" xl="3" lg="3">
                      Resim
                      <vue-dropify
                        :src="
                          stoku.resim3 != ''
                            ? $store.state.portalContentAdres +
                              $store.state.calisilanSirketId +
                              '/images/' +
                              stoku.resim3
                            : require(`../../assets/urunResmiYok.png`)
                        "
                        accept="image/*"
                       v-model="res3"
                      ></vue-dropify>
                    </b-col>
                    <b-col cols="12" xl="3" lg="3">
                      Resim
                      <vue-dropify
                        :src="
                          stoku.resim4 != ''
                            ? $store.state.portalContentAdres +
                              $store.state.calisilanSirketId +
                              '/images/' +
                              stoku.resim4
                            : require(`../../assets/urunResmiYok.png`)
                        "
                        accept="image/*"
                        v-model="res4"
                      ></vue-dropify>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Varyantlar">
            <b-row>
              <b-col>
                <b-button variant="primary" @click="varyantEkle">Yeni</b-button>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Web/Sosyal">
            <b-form-checkbox v-model="stoku.b2bAktif"> B2B Aktif </b-form-checkbox>
            <b-form-checkbox v-model="stoku.stokSifirsaGizli">
              Stok Sıfırsa Gizle
            </b-form-checkbox>
            <b-form-checkbox v-model="stoku.stokSifirGosterSatma">
              Stok Sıfırsa Göster ama Satma
            </b-form-checkbox>
          </b-tab>
        </b-tabs>
      </b-container>
    </form>
  </div>
</template>

<script>
import VueAutonumeric from "../ozel/VueAutonumeric.vue";
import AutoNumeric from "autonumeric";
import { required } from "vuelidate/lib/validators";

import { all, allSettled } from "q";
import { getEventListeners } from "events";
import VueDropify from "vue-dropify";

export default {
  components: {
    VueAutonumeric,
    VueDropify,
  },
  data() {
    return {
      res1: null,
      res2: null,
      res3: null,
      res4: null,
      arananStok: "",
      stoku: {
        Id: this.$route.params.id,
        acanUserid: 1,
        kayitTarihi: null,
        aktif: true,
        kod: "STK00001509",
        gtin: "",
        renkid: 0,
        bedenid: 0,
        barkod: "",
        alisFiyat: 0,
        normalFiyat: 4000,
        satisFiyat: 4000,
        kdvOran: 3,
        kisaAciklama: "",
        aciklama: "",
        ad: "MUHTELİF GIDA",
        seoLink: "",
        sistemMarkalarId: 0,
        kargoUcreti: 0,
        kritikStokUyari: -1,
        stokSifirsaGizli: false,
        stokSifirGosterSatma: true,
        resim1: "",
        resim2: "",
        resim3: "",
        resim4: "",
        ustid: 0,
        mevcutAdet: 16,
        sistemStokBirimlerId: null,
        varyantGrupId: 0,
        varyantAdi: null,
        b2bAktif: false,
        b2bOzelFiyat: 0,
        kdvsi: 8,
        markasi: "",
        stokBirimi: "",
      },
    };
  },
  validations: {
    stoku: {
      kod: {
        required: true,
      },
      ad: {
        required: true,
      },
    },
  },
  methods: {
    
    varyantEkle() {
      return true;
    },
    otokod() {
      return true;
    },
    arananStokGit() {
      return true;
    },
    oncekiStok() {
      return true;
    },
    sonrakiStok() {
      return true;
    },
    stokKaydet() {
      var rot = this;
  
  /* console.log(rot.res1)
  console.log(rot.res2)
  console.log(rot.res3)
  console.log(rot.res4) */
      var urlsi = "/api/stoksRoute/stokKaydet";

      var axioss = require("axios");
      //var FormData = require("form-data");

      var data = new FormData();

      if (rot.res1) {
       
        data.append("File1", rot.res1[0], rot.res1[0].name);
         console.log(rot.res1[0].name)
      }
      if (rot.res2) {
       console.log(rot.res2[0].name)
        data.append("File2", rot.res2[0], rot.res2[0].name);
          
      }
      if (rot.res3) {
        data.append("File3", rot.res3[0], rot.res3[0].name);
         console.log(rot.res3[0].name)
      }
      if (rot.res4) {
        data.append("File4", rot.res4[0], rot.res4[0].name);
          console.log(rot.res4[0].name)
      }
     
      data.append("userMail", rot.$store.state.userMail);
      data.append("userSifre", rot.$store.state.userSifre);
      data.append("sirketId", rot.$store.state.calisilanSirketId);

      rot.stoku.kdvOran = rot.$store.state.sirketAyarlar.sistemKdvOranlar.find(
        (o) => o.Id == rot.stoku.kdvOran.Id
      ).Id;
      rot.stoku.sistemStokBirimlerId = rot.$store.state.sirketAyarlar.sistemStokBirimleri.find(
        (o) => o.Id == rot.stoku.sistemStokBirimlerId.Id
      ).Id;

      data.append("stokBilgi", JSON.stringify(rot.stoku));
console.log(JSON.stringify(rot.stoku))
      var config = {
        method: "post",
        url: urlsi,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };


/* for (var pair of data.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
}
 */

      axioss(config)
        .then(function (response) {
          console.log(response);
          rot.$store.state.loadAnimAc = false;
          if (response.data.sonuc) {
            rot.$store.dispatch("sirketStoklar", { stokId: rot.stoku.Id });
            rot.$store.dispatch("makeToast", { mesaj: "Kaydedildi" });
          } else {
            rot.$dialog.alert(response.data.mesaj, { okText: "Kapat" });
          }
        })
        .catch(function (error) {
          rot.$store.state.loadAnimAc = false;
          console.log(error);
        }); 
    },
    stokOku() {
      var gelenStok = this.$store.state.sirketAyarlar.tumStoklar.find(
        (o) => o.Id == this.stoku.Id
      );
      //this.stoku  =  gelenStok
      this.stoku.ad = gelenStok.ad;

      this.stoku.aktif = gelenStok.aktif;
      this.stoku.kod = gelenStok.kod;
      this.stoku.gtin = gelenStok.gtin;
      this.stoku.renkid = gelenStok.renkid;
      this.stoku.bedenid = gelenStok.bedenid;
      this.stoku.barkod = gelenStok.barko;
      this.stoku.alisFiyat = gelenStok.alisFiyat;
      this.stoku.normalFiyat = gelenStok.normalFiyat;
      this.stoku.satisFiyat = gelenStok.satisFiyat;
      this.stoku.kdvOran = gelenStok.kdvOran;
      this.stoku.kisaAciklama = gelenStok.kisaAciklama;
      this.stoku.aciklama = gelenStok.aciklama;
      this.stoku.ad = gelenStok.ad;
      this.stoku.seoLink = gelenStok.seoLink;
      this.stoku.sistemMarkalarId = gelenStok.sistemMarkalarId;
      this.stoku.kargoUcreti = gelenStok.kargoUcreti;
      this.stoku.kritikStokUyari = gelenStok.kritikStokUyari;
      this.stoku.stokSifirsaGizli = gelenStok.stokSifirsaGizli;
      this.stoku.stokSifirGosterSatma = gelenStok.stokSifirGosterSatma;
      this.stoku.resim1 = gelenStok.resim1;
      this.stoku.resim2 = gelenStok.resim2;
      this.stoku.resim3 = gelenStok.resim3;
      this.stoku.resim4 = gelenStok.resim4;
      this.stoku.ustid = gelenStok.ustid;
      this.stoku.mevcutAdet = gelenStok.mevcutAdet;
      this.stoku.sistemStokBirimlerId = gelenStok.sistemStokBirimlerId;
      this.stoku.varyantGrupId = gelenStok.varyantGrupId;
      this.stoku.varyantAdi = gelenStok.varyantAdi;
      this.stoku.b2bAktif = gelenStok.b2bAktif;
      this.stoku.b2bOzelFiyat = gelenStok.b2bOzelFiyat;
      this.stoku.kdvsi = gelenStok.kdvsi;
      this.stoku.markasi = gelenStok.markasi;
      this.stoku.stokBirimi = gelenStok.stokBirimi;
      this.stoku.kdvOran = this.$store.state.sirketAyarlar.sistemKdvOranlar.find(
        (o) => o.Id == this.stoku.kdvOran
      );
      this.stoku.sistemMarkalarId = this.$store.state.sirketAyarlar.markaListesi.find(
        (o) => o.Id == this.stoku.sistemMarkalarId
      );
      this.stoku.sistemStokBirimlerId = this.$store.state.sirketAyarlar.sistemStokBirimleri.find(
        (o) => o.Id == this.stoku.sistemStokBirimlerId
      );

      this.stoku.resim1 =
        this.stoku.resim1 == null
          ? require(`../../assets/urunResmiYok.png`)
          : this.stoku.resim1;
      this.stoku.resim2 =
        this.stoku.resim2 == null
          ? require(`../../assets/urunResmiYok.png`)
          : this.stoku.resim2;
      this.stoku.resim3 =
        this.stoku.resim3 == null
          ? require(`../../assets/urunResmiYok.png`)
          : this.stoku.resim3;
      this.stoku.resim4 =
        this.stoku.resim4 == null
          ? require(`../../assets/urunResmiYok.png`)
          : this.stoku.resim4;
    },
  },
  created() {
    this.stokOku();
  },
  watch: {
    $route(to, from) {
      this.stoku.Id = to.params.id;
      this.stokOku();
    },
  },
};
</script>

<style scoped></style>
